<template>
  <div ref="root" class="position-relative">
    <slot />
    <div class="position-absolute" style="top: 0; left: 0; bottom: 0; right: 0">
      <canvas ref="canvas" class="w-100 h-100" :style="{ cursor }" />
    </div>
  </div>
</template>

<script type="text/javascript">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import colors from '@/stylesheets/scss/colors.module.scss';
import { useElMousePosition, useElMousedown } from './mouse';
import Rect from './Rect';

const convertToRatio = ({ x, y }, { width, height }) => ({ x: x / width, y: y / height });
const convertFromRatio = ({ x, y }, { width, height }) => ({ x: x * width, y: y * height });

const DEFAULT_ACCURACY = 5;

const includesPoint =
  ({ x, y }, accuracy = DEFAULT_ACCURACY) =>
  (rect) =>
    x >= rect.left - accuracy && x <= rect.right + accuracy && y >= rect.top - accuracy && y <= rect.bottom + accuracy;

const getLineAt = ({ x, y }, rect, accuracy = DEFAULT_ACCURACY) => {
  if (Math.abs(rect.top - y) <= accuracy && Math.abs(rect.left - x) <= accuracy)
    return { type: 'corner', placement: 'topLeft' };
  if (Math.abs(rect.top - y) <= accuracy && Math.abs(rect.right - x) <= accuracy)
    return { type: 'corner', placement: 'topRight' };
  if (Math.abs(rect.bottom - y) <= accuracy && Math.abs(rect.left - x) <= accuracy)
    return { type: 'corner', placement: 'bottomLeft' };
  if (Math.abs(rect.bottom - y) <= accuracy && Math.abs(rect.right - x) <= accuracy)
    return { type: 'corner', placement: 'bottomRight' };
  if (Math.abs(rect.top - y) <= accuracy) return { type: 'border', placement: 'top' };
  if (Math.abs(rect.bottom - y) <= accuracy) return { type: 'border', placement: 'bottom' };
  if (Math.abs(rect.left - x) <= accuracy) return { type: 'border', placement: 'left' };
  if (Math.abs(rect.right - x) <= accuracy) return { type: 'border', placement: 'right' };

  const xDividerIndex = rect.xDividers.findIndex((line) => {
    return Math.abs(line - x) <= accuracy;
  });
  if (xDividerIndex !== -1) {
    return { type: 'xDivider', placement: xDividerIndex };
  }

  const yDividerIndex = rect.yDividers.findIndex((line) => {
    return Math.abs(line - y) <= accuracy;
  });
  if (yDividerIndex !== -1) {
    return { type: 'yDivider', placement: yDividerIndex };
  }
};
export default {
  props: {
    rects: { type: Array, default: () => [] },
    editable: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const root = ref(null);
    const canvas = ref(null);

    const rects = ref([]);
    watch(
      () => canvas.value && props.rects,
      (propRects) => {
        if (!propRects) return;
        rects.value = propRects?.map((rect) => rect.map((coordinate) => convertFromRatio(coordinate, canvas.value)));
      },
      { immediate: true, deep: true }
    );

    const updateCanvasDimensions = () => {
      const { width, height } = canvas.value.getBoundingClientRect();
      Object.assign(canvas.value, { width, height });
    };
    const observer = new MutationObserver(updateCanvasDimensions);
    onMounted(() => {
      updateCanvasDimensions();
      observer.observe(root.value.children[0], { attributes: true });
      window.addEventListener('resize', updateCanvasDimensions);
    });
    onBeforeUnmount(() => {
      observer.disconnect();
      window.removeEventListener('resize', updateCanvasDimensions);
    });

    const { x, y } = useElMousePosition(canvas);
    const mousedown = useElMousedown(canvas);

    const overRect = computed(() => rects.value.find(includesPoint({ x: x.value, y: y.value })));
    const overLine = computed(() => {
      return rects.value
        .filter(includesPoint({ x: x.value, y: y.value }))
        .map((rect) => ({ rect, line: getLineAt({ x: x.value, y: y.value }, rect) }))
        .find(({ line }) => line);
    });

    const cursor = computed(() => {
      if (!props.editable) return 'auto';
      if (overLine.value) {
        const { type, placement } = overLine.value.line;
        if (['top', 'bottom'].includes(placement)) return 'ns-resize';
        if (['left', 'right'].includes(placement)) return 'ew-resize';
        if (['topLeft', 'bottomRight'].includes(placement)) return 'nwse-resize';
        if (['topRight', 'bottomLeft'].includes(placement)) return 'nesw-resize';
        if (type === 'xDivider') return 'col-resize';
        if (type === 'yDivider') return 'row-resize';
      }
      return 'auto';
    });

    let createRect = null;
    let moveLine = null;
    let clickRect = null;

    const emitUpdate = () =>
      emit(
        'update:rects',
        rects.value.map((rect) => rect.map((p) => convertToRatio(p, canvas.value)))
      );
    watch(
      mousedown,
      (mousedown) => {
        if (!props.editable) return;
        if (mousedown) {
          if (overLine.value) {
            moveLine = overLine.value;
            return;
          }
          if (overRect.value) {
            clickRect = overRect.value;
            return;
          }
          createRect = { point: { x: x.value, y: y.value } };
        } else {
          // mouseup
          if (createRect) {
            if (createRect.rect) {
              rects.value = [...rects.value, createRect.rect];
              emitUpdate();
            }
            createRect = null;
          }
          if (clickRect) {
            if (overRect.value === clickRect)
              emit(
                'click:rect',
                clickRect.map((p) => convertToRatio(p, canvas.value)),
                rects.value.indexOf(clickRect)
              );
            clickRect = null;
          }
          if (moveLine) {
            emitUpdate();
            moveLine = null;
          }
        }
      },
      { immediate: true }
    );
    watch(
      [x, y],
      ([x, y]) => {
        if (!canvas.value) return;
        if (createRect) {
          createRect.rect = Rect.createFromPoints([createRect.point, { x, y }]);
        }
        if (moveLine) {
          const {
            line: { type, placement },
            rect,
          } = moveLine;
          if (type === 'border' || type === 'corner') {
            if (['top', 'topLeft', 'topRight'].includes(placement)) rect.top = y;
            if (['bottom', 'bottomLeft', 'bottomRight'].includes(placement)) rect.bottom = y;
            if (['left', 'topLeft', 'bottomLeft'].includes(placement)) rect.left = x;
            if (['right', 'topRight', 'bottomRight'].includes(placement)) rect.right = x;
          }
          if (type === 'xDivider') rect.setXDivider(placement, x);
          if (type === 'yDivider') rect.setYDivider(placement, y);
        }
        redraw();
      },
      { immediate: true }
    );

    const redraw = () => {
      if (!canvas.value) return;
      const ctx = canvas.value.getContext('2d');
      ctx.strokeStyle = colors.primary;
      ctx.lineWidth = 1.5;
      ctx.fillStyle = colors.primary + '40';

      const { width, height } = canvas.value;
      ctx.clearRect(0, 0, width, height);
      rects.value.forEach((rect) => {
        ctx.strokeRect(rect.topLeft.x, rect.topLeft.y, rect.width, rect.height);
        ctx.beginPath();
        rect.xDividers.forEach((line) => {
          ctx.moveTo(line, rect.top);
          ctx.lineTo(line, rect.bottom);
        });
        rect.yDividers.forEach((line) => {
          ctx.moveTo(rect.left, line);
          ctx.lineTo(rect.right, line);
        });
        rect.fills.forEach(([columnIndex, rowIndex]) => {
          const x = columnIndex ? rect.xDividers[columnIndex - 1] : rect.left;
          const y = rowIndex ? rect.yDividers[rowIndex - 1] : rect.top;
          const width = (rect.xDividers[columnIndex] || rect.right) - x;
          const height = (rect.yDividers[rowIndex] || rect.bottom) - y;
          ctx.fillRect(x, y, width, height);
        });
        ctx.stroke();
      });

      if (overRect.value && props.editable && !mousedown.value) {
        ctx.save();
        ctx.lineWidth = 3;
        ctx.strokeRect(overRect.value.left, overRect.value.top, overRect.value.width, overRect.value.height);
        ctx.restore();
      }

      const rect = createRect?.rect;
      if (!rect) return;
      ctx.strokeRect(rect.topLeft.x, rect.topLeft.y, rect.width, rect.height);
    };

    watch(rects, redraw, { immediate: true });

    return { root, canvas, cursor };
  },
};
</script>
