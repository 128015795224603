<template>
  <el-dropdown
    v-bind="$attrs"
    ref="dropdownRef"
    :placement="$direction === 'rtl' ? 'bottom-end' : 'bottom-start'"
    @command="(args) => $emit('on-choose-item', args)"
  >
    <TableFilter
      :filter-name="filterName"
      :filter-value="filterValue"
      :active="isDropdownVisible"
      :clearable="clearable"
      :is-dark-theme="isDarkTheme"
      :is-value-weight-bold="isValueWeightBold"
      @on-clear-filter="$emit('on-clear-filter')"
    >
      <slot slot="icon" name="icon"></slot>
      <slot slot="filter-value" name="filter-value"></slot>
    </TableFilter>
    <slot slot="dropdown" name="dropdown"></slot>
  </el-dropdown>
</template>
<script>
import { ref, computed } from 'vue';
import TableFilter from './TableFilter';
export default {
  components: { TableFilter },
  props: {
    filterName: { type: String, default: null },
    filterValue: { type: String, default: null },
    clearable: { type: Boolean, default: false },
    isDarkTheme: { type: Boolean, default: false },
    isValueWeightBold: { type: Boolean, default: false },
  },
  emits: ['on-choose-item', 'on-clear-filter'],
  setup() {
    const dropdownRef = ref(null);
    const isDropdownVisible = computed(() => dropdownRef?.value?.visible || false);

    return {
      dropdownRef,
      isDropdownVisible,
    };
  },
};
</script>
<style lang="scss" scoped></style>
