<template>
  <div>
    <div ref="contentContainer" :style="{ maxHeight: isExpanded ? 'none' : `${maxHeight}px`, overflow: 'hidden' }">
      <slot name="content" />
    </div>
    <Button
      v-if="isOverflowing"
      type="link"
      class="text-typography-primary text-decoration-underline p-0 pt-2"
      @click="isExpanded = !isExpanded"
    >
      {{ $t(`commons.${isExpanded ? 'collapse' : 'continueReading'}`) }}
    </Button>
  </div>
</template>

<script>
import { ref } from 'vue';

import { Button } from './';

export default {
  components: { Button },
  props: {
    maxHeight: { type: Number, required: true },
  },
  setup() {
    return { isOverflowing: ref(false), isExpanded: ref(false) };
  },
  mounted() {
    this.$nextTick(() => {
      const contentHeight = this.$refs.contentContainer?.scrollHeight;
      this.isOverflowing = contentHeight > this.maxHeight;
    });
  },
};
</script>
