export default class Rect {
  constructor({
    topLeft = { x: 0, y: 0 },
    bottomRight = { x: 1, y: 1 },
    xDividers = [
      /* xPoint */
    ],
    yDividers = [
      /* yPoint */
    ],
    fills = [
      /* [columnIndex, rowIndex] */
    ],
  } = {}) {
    this.topLeft = { ...topLeft };
    this.bottomRight = { ...bottomRight };
    this.xDividers = [...xDividers];
    this.yDividers = [...yDividers];
    this.fills = [...fills];
  }

  static create(options) {
    return new this(options);
  }

  static createFromPoints([p1 = { x: 0, y: 0 }, p2 = { x: 1, y: 1 }] = []) {
    return new this({
      topLeft: { x: Math.min(p1.x, p2.x), y: Math.min(p1.y, p2.y) },
      bottomRight: { x: Math.max(p1.x, p2.x), y: Math.max(p1.y, p2.y) },
    });
  }

  includes({ x, y }) {
    return x >= this.left && x <= this.right && y >= this.top && y <= this.bottom;
  }

  map(callback) {
    return new this.constructor({
      topLeft: callback(this.topLeft),
      bottomRight: callback(this.bottomRight),
      xDividers: this.xDividers.map((x) => callback({ x, y: 0 }).x),
      yDividers: this.yDividers.map((y) => callback({ x: 0, y }).y),
      fills: this.fills,
    });
  }

  get width() {
    return this.bottomRight.x - this.topLeft.x;
  }

  get height() {
    return this.bottomRight.y - this.topLeft.y;
  }

  get left() {
    return this.topLeft.x;
  }

  set left(value) {
    const limit = this.xDividers.length ? this.xDividers[0] : this.right;
    this.topLeft.x = Math.min(value, limit);
  }

  get right() {
    return this.bottomRight.x;
  }

  set right(value) {
    const limit = this.xDividers.length ? this.xDividers[this.xDividers.length - 1] : this.left;
    this.bottomRight.x = Math.max(value, limit);
  }

  get top() {
    return this.topLeft.y;
  }

  set top(value) {
    const limit = this.yDividers.length ? this.yDividers[0] : this.bottom;
    this.topLeft.y = Math.min(value, limit);
  }

  get bottom() {
    return this.bottomRight.y;
  }

  set bottom(value) {
    const limit = this.yDividers.length ? this.yDividers[this.yDividers.length - 1] : this.top;
    this.bottomRight.y = Math.max(value, limit);
  }

  setXDivider(index, x) {
    const leftLimit = index === 0 ? this.left : this.xDividers[index - 1];
    const rightLimit = index === this.xDividers.length - 1 ? this.right : this.xDividers[index + 1];
    this.xDividers[index] = Math.max(leftLimit, Math.min(rightLimit, x));
  }

  setYDivider(index, y) {
    const topLimit = index === 0 ? this.top : this.yDividers[index - 1];
    const bottomLimit = index === this.yDividers.length - 1 ? this.bottom : this.yDividers[index + 1];
    this.yDividers[index] = Math.max(topLimit, Math.min(bottomLimit, y));
  }
}
