<template>
  <div>
    <div v-if="exportOptions.length">
      <ButtonWithDropdown
        :text="$t('terms.supplierTerms.supplierTermTable.excel.export')"
        :dropdown-options="exportOptions"
        :disabled="disabled"
        :should-close-dropdown-on-scroll="true"
        @on-dropdown-choice="(val) => $emit('on-export-option-choice', val)"
      >
        <div>
          <ExportExcelIcon :class="{ secondary: disabled }" />
          <ExportExcelArrowIcon :class="{ secondary: disabled }" />
        </div>
      </ButtonWithDropdown>
    </div>
    <div v-else>
      <Button
        type="secondary"
        class="export-to-excel-button"
        :disabled="disabled"
        @click="(val) => $emit('on-export-option-choice', val)"
      >
        <div class="export-to-excel-content">
          <ExportExcelIcon :class="{ secondary: disabled }" />
          <p>{{ $t('terms.supplierTerms.supplierTermTable.excel.export') }}</p>
        </div>
      </Button>
    </div>
  </div>
</template>
<script>
import { ExportExcelIcon, ExportExcelArrowIcon } from '@/assets/icons';

import { Button } from './';
import ButtonWithDropdown from './ButtonWithDropdown';

export default {
  name: 'ExcelExport',
  components: { ExportExcelIcon, ExportExcelArrowIcon, ButtonWithDropdown, Button },
  props: {
    exportOptions: { type: Array, required: false, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  emits: ['on-export-option-choice'],
};
</script>
<style lang="scss" scoped>
.export-to-excel-button {
  padding: 0.25rem;
  margin: 0;
  .export-to-excel-content {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    p {
      font-weight: 500;
      padding-inline-end: 4px;
    }
  }
}
</style>
