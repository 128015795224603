import { ref, watch } from 'vue';

export const useElMousePosition = (target) => {
  const x = ref(0);
  const y = ref(0);
  watch(
    target,
    (el, _, onCleanup) => {
      if (!el) return;
      const moveHandler = (event) => {
        const { left, top } = el.getBoundingClientRect();
        x.value = event.clientX - left;
        y.value = event.clientY - top;
      };
      el.addEventListener('mousemove', moveHandler);
      onCleanup(() => el.removeEventListener('mousemove', moveHandler));
    },
    { immediate: true }
  );
  return { x, y };
};

export const useElMousedown = (target) => {
  const mousedown = ref(false);
  const onMousedown = (event) => {
    if (event.which === 3) return;
    mousedown.value = true;
  };
  const onMouseup = (event) => {
    if (event.which === 3) return;
    mousedown.value = false;
  };
  watch(
    target,
    (el, _, onCleanup) => {
      if (!el) return;
      el.addEventListener('mousedown', onMousedown);
      el.addEventListener('mouseup', onMouseup);
      onCleanup(() => {
        mousedown.value = false;
        el.removeEventListener('mousedown', onMousedown);
        el.removeEventListener('mouseup', onMouseup);
      });
    },
    { immediate: true }
  );
  return mousedown;
};
